import React from "react";

export default function Home() {

  React.useEffect(() => {

    const script = document.createElement("script");

    script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=80fe537d-e36b-44e2-85d1-3bb25e42abc4";
    script.async = true;

    document.body.appendChild(script);

  }, [])

  return (<div><h1>Home</h1> <div id="amzn-assoc-ad-80fe537d-e36b-44e2-85d1-3bb25e42abc4"></div></div>);
}
