import Amplify, { API, graphqlOperation } from "aws-amplify";
import appSyncConfig from "./../aws-exports";

Amplify.configure(appSyncConfig);

export const getEtfs =  `
query {
    getEtfs(id: "EtfItemCount") {
        id
        itemsCount
        sort
        ticker
      }
}`;

export const QueryEtfs = async () => {
  const allEtfs = await API.graphql(graphqlOperation(getEtfs));

  const allEtfItems = allEtfs.data.getEtfs;

  const tableArray = allEtfItems.map((item) => {
    const ticker = item.ticker;
    const itemsCount = item.itemsCount;
    return { ticker, itemsCount };
  });

  return tableArray;
};