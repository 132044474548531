import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { QueryEtfs } from "./../GraphQL/getEtfs";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 640,
  },
});

export default function EtfList() {
  const classes = useStyles();

  const [etfItems, setEtfItems] = useState([]);

  useEffect(() => {
    readAllEtfs();
  }, []);

  const readAllEtfs = () => {
    (async () => {
      const etfs = await QueryEtfs();
      setEtfItems(etfs);
    })();
  };

  const columns = [
    { id: "ticker", label: "Ticker", minWidth: 170 },
    { id: "itemsCount", label: "Count", minWidth: 100 },
  ];

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {etfItems.slice().map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.ticker}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
