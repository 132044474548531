import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import SearchResult from "./SearchResult";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import { QueryEtfs } from "./../GraphQL/getEtfs";
import { QueryEtfItems } from "./../GraphQL/getEtfItems";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  formular: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  textField: {
    width: "25ch",
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    width: "25ch",
  },
  iconButton: {
    width: "5ch",
  },
}));

export default function Main() {
  const classes = useStyles();

  const [rows, setRows] = useState([
    {
      ticker: "",
      isin: "",
      name: "",
      gewichtung: 0,
    },
  ]);
  const [tickerList, setTickerList] = useState([
    { ticker: "", betrag: "" },
    { ticker: "", betrag: "" },
  ]);

  const [etfItems, setEtfItems] = useState([]);

  useEffect(() => {
    (async () => {
      const etfs = await QueryEtfs();
      const etfsMenuItems = etfs.map((etf) => {
        const value = etf.ticker;
        const label = etf.ticker;
        return { value, label };
      });
      setEtfItems(etfsMenuItems);
    })();
  }, []);

  function createData(isin, gewichtung, kurs, name) {
    return { isin, gewichtung, kurs, name };
  }

  const handleTextFieldTickerChange = (event, index) => {
    const value = event.target.value;
    const list = [...tickerList];
    list[index]["ticker"] = value;
    setTickerList(list);
  };

  const handleTextFieldBetragChange = (event, index) => {
    const value = event.target.value;
    const list = [...tickerList];
    list[index]["betrag"] = value;
    setTickerList(list);
  };

  const handleReset = async () => {
    getDynamDbData();
  };

  const getDynamDbData = async () => {};

  const handleAnalyzePortfolio = async () => {
    console.log("handleAnalyzePortfolio");
    //const etfItems = await QueryEtfItems(tickerList);
    const etfItems = await QueryEtfItems(tickerList);
    console.log("etfItems", etfItems);

    setRows(etfItems);
  };

  const handleAddClick = () => {
    setTickerList([...tickerList, { ticker: "" }]);
  };

  const handleRemoveClick = (index) => {
    const list = [...tickerList];
    list.splice(index, 1);
    setTickerList(list);
  };

  return (
    <div className={classes.root}>
      {tickerList.map((x, i) => {
        return (
          <div key={i} className="box">
            {" "}
            <TextField
              id="ticker"
              className={classes.textField}
              select
              label="Ticker"
              value={x.ticker}
              onChange={(e) => handleTextFieldTickerChange(e, i)}
            >
              {" "}
              {etfItems.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              id="betrag"
              className={classes.textField}
              label="Betrag"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={x.betrag}
              onChange={(e) => handleTextFieldBetragChange(e, i)}
            />
            {tickerList.length !== 1 && (
              <IconButton
                aria-label="remove"
                className={classes.iconButton}
                onClick={handleRemoveClick}
              >
                <RemoveIcon fontSize="large" />
              </IconButton>
            )}
            {tickerList.length - 1 === i && (
              <IconButton
                aria-label="add"
                className={classes.iconButton}
                onClick={handleAddClick}
              >
                <AddIcon fontSize="large" />
              </IconButton>
            )}
          </div>
        );
      })}
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleAnalyzePortfolio}
      >
        Analyze Portfolio
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleReset}
      >
        Reset
      </Button>
      <SearchResult rows={rows} />
    </div>
  );
}
