import Amplify, { API, graphqlOperation } from "aws-amplify";
import appSyncConfig from "./../aws-exports";

Amplify.configure(appSyncConfig);

export const getEtfItems = `
query GetEtfItems($id: ID!) {
    getEtfItems(id: $id) {
      id
      ISIN
      sort
      ticker
      Name
      weighting
      }
}`;

export const QueryEtfItems = async (tickerList) => {
  let etfItemsTotal = [];

  //const totalSum = tickerList.reduce((a, b) => parseInt(a.betrag) + parseInt(b.betrag), 0)
  const totalSum = tickerList.reduce((a, b) => a + parseInt(b.betrag), 0);

  //merge
  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const start = async () => {
    await asyncForEach(tickerList, async (tickerItem) => {
      const id = "ETF#" + tickerItem.ticker;

      const etfItemsGraphqlResult = await API.graphql(
        graphqlOperation(getEtfItems, { id: id })
      );

      const etfItems = etfItemsGraphqlResult.data.getEtfItems;

      const tableArray = etfItems.map((item) => {
        const ticker = item.ticker;
        const isin = item.ISIN;
        const name = item.Name;
        const weighting = parseFloat(item.weighting);
        const betrag = parseFloat(tickerItem.betrag);

        return { ticker, isin, name, weighting, betrag };
      });

      etfItemsTotal.push(tableArray);
    });
  };
  await start();

  const etfItemsTotalMerged = [].concat(...etfItemsTotal);

  console.log("etfItemsTotalMerged", etfItemsTotalMerged);

  //sum
  const etfItemsTotalSum = etfItemsTotalMerged.reduce((acc, curr) => {
    if (!acc[curr.isin]) {
      acc[curr.isin] = {
        ...curr,
        countInEtfs: 1,
        weighting: curr.weighting,
        betrag: curr.betrag,
        weightingBetrag: (curr.weighting * curr.betrag) / 100,
      };
      return acc;
    }
    acc[curr.isin].countInEtfs += 1;
    acc[curr.isin].weighting += curr.weighting;
    acc[curr.isin].betrag += curr.betrag;
    acc[curr.isin].weightingBetrag += (curr.weighting * curr.betrag) / 100;

    return acc;
  }, {});

  console.log("EtfItemsTotalSum", etfItemsTotalSum);

  //calculate
  const etfItemsTotaCalculated = Object.keys(etfItemsTotalSum).map((isin) => {
    const item = etfItemsTotalSum[isin];
    const anteil = (item.weightingBetrag / totalSum) * 100;

    return {
      isin: item.isin,
      name: item.name,
      countInEtfs: item.countInEtfs,
      weighting: item.weighting,
      betrag: item.weightingBetrag,
      anteil: anteil,
    };
  });

  return etfItemsTotaCalculated;
  /*
  const etfItemsGraphqlResult = await API.graphql(
    graphqlOperation(getEtfItems, { id: id })
  );

  const etfItems = etfItemsGraphqlResult.data.getEtfItems;

  const tableArray = etfItems.map((item) => {
    const ticker = item.ticker;
    const isin = item.ISIN;
    const name = item.Name;
    const weighting = parseFloat(item.weighting);

    return { ticker, isin, name, weighting };
  }); */

  //return tableArray;
};
